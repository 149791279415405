<template>
  <v-container class="">

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj} from "@/helper/helper";

export default {
  name: "VerifyEmail",
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    })
  },
data(){
    return{
      message: msgObj(),
      secret: this.$route.query.secret,
      uid: this.$route.query.userId,
    }
},
methods:{

},
mounted() {
    this.$store.dispatch('auth/completeVerifyMail', {
      uid: this.uid,
      secret: this.secret,
    }).then(()=>{
      this.$router.push('/planner/dashboard?newAccount=true')
    }).catch((err)=>{
      this.message = error(err)
    })
}
}
</script>

<style scoped>
v-row {
  width: 100vw !important;
}

h1{
  font-weight: 300;
}

v-card{
  backdrop-filter: blur(14px) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.background{
  background-image:url(https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80);
  height: 102vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-row{
  top: 50%;
  display: flex;
}

.transparent-card{
  backdrop-filter: blur(9px) !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

</style>
